import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LogoItem from "./LogoItem"
export default function Logos() {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "logos" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `)
  const posts = data.allFile.nodes

  return (
    <div className="w-full">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-12 md:gap-x-32 md:gap-y-16 multiply items-center">
        {posts.map((post, i) => {
          return <LogoItem key={i} post={post} />
        })}
      </div>
    </div>
  )
}
