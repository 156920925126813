import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function LogoItem({ post }) {
  const { childImageSharp } = post
  const image = getImage(childImageSharp)

  return (
    <div className="w-full">
      <GatsbyImage image={image} alt="logo" className=" " />
    </div>
  )
}
