import React from "react"
import Counter from "../components/Counter"
import Intro from "../components/index/Introboxes"
import Referenser from "../components/index/Referenser"
import Systerbolag from "../components/index/Systerbolag"
import Logos from "../components/index/Logos"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BigLogo from "../components/index/BigLogo"
import Ingress from "../components/index/Ingress"

function IndexPage() {
  return (
    <Layout isHome>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Stab Teknik AB"
        isHome
      />

      <section className="px-4 md:px-8 py-8 md:py-16">
        <Ingress />
      </section>

      <div>
        <BigLogo />
      </div>
      <section className="mx-auto max-w-6xl pt-16 text-xl pb-8">
        <Referenser />
      </section>
      <section className="px-4 mx-auto max-w-6xl py-8">
        <Logos />
      </section>

      <section className="mx-auto max-w-6xl py-16 text-xl">
        <Systerbolag />
      </section>
    </Layout>
  )
}

export default IndexPage
