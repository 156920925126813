import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Intro from "./Introboxes"
import Usps from "./Usps"

export default function Ingress() {
  return (
    <div className=" max-w-7xl mx-auto">
      <div className="flex flex-col lg:flex-row lg:space-x-4 items-center py-8 md:py-16">
        {/* <div className="w-full lg:w-1/2 flex justify-end">
          <Usps />
        </div> */}
        <div
          id="om"
          className=" text-xl leading-relaxed flex flex-col lg:flex-row gap-8 lg:gap-16"
        >
          <div className="flex-1 space-y-8">
            <h2 className=" font-sans text-4xl font-bold tracking-tight leading-normal text-brand">
              Vad vi gör
            </h2>

            <p>
              Stab Teknik AB har varit aktiva i mer än 30 år och har hunnit med
              många spännande projekt, stora som små. Vårt fokus ligger på
              rivning, skrotning, ombyggnadsprojekt, demontering samt
              återvinning av järn och metall. Tillsammans med våra systerbolag
              ingår vi i en stark företagsgrupp som idag är ledande inom sina
              respektive affärsområden. Gruppen består av Stab Teknik AB,
              Krandepån Södra AB och Körplåt i Skåne AB.
            </p>
            <p>
              Tack vare den kompetens som byggts upp i företagsgruppen är vi
              idag unika och kan därför ta oss an projekt som för många andra är
              omöjliga. Oavsett om det gäller en rivning av en 50 meter hög
              hamnkran eller en processlinje i en fabrik som har full produktion
              kan vi erbjuda Er en komplett entreprenad. Våra uppdrag är väldigt
              varierande, såsom selektiv rivning av känslig utrustning,
              demontage av industrihallar eller korta uppdrag i form av mindre
              rivningar. Från norr till söder strävar vi efter att bli Ert
              första val inom såväl rivning som skrotning, delprojekt och
              totalentreprenader.
            </p>

            <h2 className=" font-sans text-4xl font-bold tracking-tight leading-normal text-brand">
              Kvalitet / Miljö
            </h2>
            <p>
              Vår kvalitetspolicy bygger på ISO 9001:2015. All dokumentation och
              målsättning för varje uppdrag skall präglas av högsta möjliga
              kvalitetskrav. Kontinuerlig utbildning av vår personal tillsammans
              med modern teknisk utrustning och stor fordonskapacitet är ett
              kvalificerat led i våra kvalitetskrav. Inom hela vårt företag
              arbetar vi miljömedvetet enligt ISO 14001:2015. Vi är certifierade
              för systematiskt arbetsmiljöarbete enligt ISO 45001:2018.
            </p>
          </div>
          <div className="flex-1 flex flex-col gap-4 md:gap-8">
            <div>
              <StaticImage src="../../images/kran.jpg" className="shadow-md" />
            </div>
            <div>
              <StaticImage src="../../images/lans.jpg" className="shadow-md" />
            </div>
          </div>
        </div>
      </div>
      <div className="-mt-4 md:-mt-0">
        <StaticImage src="../../images/kockums.jpg" className="shadow-lg" />
      </div>
    </div>
  )
}
