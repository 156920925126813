import { Link } from "gatsby"
import React from "react"

export default function Referenser() {
  return (
    <div className="mx-4 flex flex-col gap-4 lg:gap-8">
      <div>
        <div className="flex flex-col gap-4">
          <p>
            Stab Teknik AB eftersträvar att vara helt självförsörjande inom
            segmentet rivning/sanering.
          </p>
          <p>
            Förutom mycket erfaren och kunnig personal förfogar vi över en stor
            mängd olika resurser, såsom mobilkranar upp till 300 ton,
            materialhanterare, mobila skrotsaxar, truckar upp till 18 tons
            lyftkapacitet samt kranbilar mellan 26 och 110 tm.
          </p>
          <p>
            Utöver detta har vi stor erfarenhet inom skärbränning, lansning,
            asbestsanering, tunggodshantering, selektiv rivning med mera.
          </p>
          {/* <p>
            Besök oss gärna på{" "}
            <Link to="https://www.instagram.com">Instagram</Link> för att se
            urval av våra uppdrag.
          </p> */}
        </div>
      </div>
      <div>
        <h3 className="text-3xl mb-8 font-bold border-b-4 pb-4 border-brand ">
          Referenser
        </h3>
        <p>
          Vårt kundregister är både stort och omväxlande. Med stora och små
          företag som alla kräver kvalificerad kunskap och kvalitet. Inget
          uppdrag är för stort eller litet. Därför är variationen på
          uppdragsgivare stor. Den gemensamma nämnaren är att alla ställer höga
          krav på fasta leveranstider och priser. Önskar du komma i kontakt med
          några av våra kunder, kontakta oss så får du namn och telefonnummer
          till aktuell person.{" "}
        </p>
      </div>
    </div>
  )
}
