import React from "react"
import logo from "../../images/stab-stor.svg"
export default function BigLogo() {
  return (
    <div className="bg-brand w-full px-4 py-32">
      <div className="container mx-auto max-w-6xl">
        <img src={logo} />
      </div>
    </div>
  )
}
